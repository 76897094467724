import React from 'react';
import PropTypes from 'prop-types';

const HeadingSection = ({ h1, h2, image }) => (
  <section className="heading-section">
    <div className="container">
      <div className="row">
        <div className="col-md-7 content">
          <h1>{h1}</h1>
          {h2 && <h2>{h2}</h2>}
        </div>
        <div className="col-md-5 image">
          <img className="w-100" src={image} alt={h1} />
        </div>
      </div>
    </div>
  </section>
);

HeadingSection.propTypes = {
  h1: PropTypes.string.isRequired,
  h2: PropTypes.string,
};

export default HeadingSection;
