import React from 'react';

import HeadingSection from '../../HeadingSection';

import imageIllustrationPrivacyPolicy from '../../../assets/images/illustrations/Privacy_policy.png';

const TermsSection = ({title, content}) => (
  <>
    <div className="common-page privacy-page">
      <HeadingSection h1={title} image={imageIllustrationPrivacyPolicy} />
      <section className="second-section">
        <div className="container">
            {content}
        </div>
      </section>
    </div>
  </>
);

export default TermsSection;
